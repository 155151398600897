@use 'sass:math';

@import 'vars';

$generic-fonts: system-ui, helvetica, arial, sans-serif;

/// Check if two scss values are safe to add, subtract, or multiply.
/// @example scss Input
///  @if is-compatible($font-size, $line-height) {
///    @return math.div($line-height, $font-size);
///  } @else {
///    @return $line-height;
///  }
@function is-compatible($a, $b) {
  // Check if both values are numbers, for scss values like 3% or 10px count as numbers
  @if type-of($a) == 'number' and type-of($b) == 'number' {
    // Check if they are unitless so we can do transformations
    @if math.is-unitless($a) and math.is-unitless($b) {
      // Extra check to make sure they are compatible for math.div later on
      @return math.compatible($a, $b);
    } @else {
      @return false;
    }
  } @else {
    @return false;
  }
}

// Use two pixel values to convert line-height into a unitless number
// @example scss Input
//   p {
//     font-size: 16px;
//     line-height: line-height(16, 30);
//   }
@function line-height($font-size, $line-height) {
  @if is-compatible($font-size, $line-height) {
    @return math.div($line-height, $font-size);
  } @else {
    @return $line-height;
  }
}

// Use two pixel values to convert letter-spacing into an em value
// @example scss Input
//   p {
//     font-size: 16px;
//     // letter-spacing: -0.1px;
//     letter-spacing: letter-spacing(16, -0.1);
//   }
@function letter-spacing($font-size, $letter-spacing) {
  @if is-compatible($font-size, $letter-spacing) {
    @return #{math.div($letter-spacing, $font-size)}em;
  } @else {
    @return $letter-spacing;
  }
}

// Define font-size, line-height and letter-spacing in function
// @example scss Input
//   p {
//     // font-size: 16px;
//     // letter-spacing: -0.1px;
//     // line-height: 1.5; // This is line-height 24px and font-size: 16px
//     @include font-size(16, 24, -0.1)
//   }
@mixin font-size($font-size, $line-height: null, $letter-spacing: null) {
  @if math.is-unitless($font-size) {
    font-size: px($font-size);
  } @else {
    font-size: $font-size;
  }

  line-height: line-height($font-size, $line-height);
  letter-spacing: letter-spacing($font-size, $letter-spacing);
}

// Define font-family, font-weight and font-style in function
// @example scss Input
//   p {
//     // font-family: 'Roboto';
//     // font-weight: 400;
//     // font-style: italic;
//     @include font-family('Roboto', 400, italic)
//   }
@mixin font-family($font-family: null, $font-weight: null, $font-style: null) {
  @if $font-family {
    font-family: $font-family, $generic-fonts;
  }
  font-weight: $font-weight;
  font-style: $font-style;
}

// Typography System
// Also update .storybook/intro/3.Typography.stories.mdx

@mixin typography-h1($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(50, 64, -0.03em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(92, 100, -0.03em);
  }

  @include media-desktop {
    @include font-family('romie', 400);
    @include font-size(140, 116, -0.03em);
  }
}

@mixin typography-h2($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(42, 48, -0.06em);
  text-transform: $text-transform;
  font-style: $font-style;

  // @NOTE: Exception for PageHome headers on mobile
  @include media-below-mobile {
    @include font-size(38, 45, -0.07em);
  }

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(92, 100, -0.06em);
  }
}

// Exception for smaller h2
@mixin typography-smaller-h2($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(42, 48, -0.05em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 300);
    @include font-size(70, 70, -0.05em);
  }
}

@mixin typography-h3($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(32, 40, -0.02em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(48, 56, -0.02em);
  }

  @include media-desktop {
    @include font-family('romie', 400);
    @include font-size(56, 56, -0.02em);
  }
}

@mixin typography-h4($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(24, 32, -0.05em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(32, 44, -0.05em);
  }
}

@mixin typography-bodycopy-1($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(17, 25);
  font-style: $font-style;
}

@mixin typography-bodycopy-2($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(12, 18, 0.02em);
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(14, 18, 0.02em);
  }

  @include media-desktop {
    @include font-family('romie', 400);
    @include font-size(12, 18, 0.02em);
  }
}

@mixin typography-caption($text-transform: null, $font-style: null) {
  @include font-family('neue-montreal', 300);
  @include font-size(14, 16, 0.03em);
  font-style: $font-style;

  @include media-desktop {
    @include font-family('neue-montreal', 300);
    @include font-size(12, 15, 0.03em);
  }
}

@mixin typography-label-1($text-transform: null, $font-style: null) {
  @include font-family('neue-montreal', 400);
  @include font-size(14, 16, 0.03em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('neue-montreal', 400);
    @include font-size(16, 34, 0.03em);
  }
}

@mixin typography-label-2($text-transform: null, $font-style: null) {
  @include font-family('neue-montreal', 400);
  @include font-size(12, 16, 0.02em);
  text-transform: $text-transform;
  font-style: $font-style;
}

@mixin typography-label-3($text-transform: null, $font-style: null) {
  @include font-family('neue-montreal', 400);
  @include font-size(10, 18, 0.03em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('neue-montreal', 400);
    @include font-size(12, 16, 0.02em);
  }

  @include media-desktop {
    @include font-family('neue-montreal', 400);
    @include font-size(10, 18, 0.03em);
  }
}

@mixin typography-404($text-transform: null, $font-style: null) {
  @include font-family('romie', 400);
  @include font-size(88, 76, -0.03em);
  text-transform: $text-transform;
  font-style: $font-style;

  @include media-tablet {
    @include font-family('romie', 400);
    @include font-size(116, 96, -0.03em);
  }

  @include media-desktop {
    @include font-family('romie', 400);
    @include font-size(140, 116, -0.03em);
  }
}

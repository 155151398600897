@import 'shared';

.root {
  display: block;
  position: relative;

  .nextImage {
    width: 100%;
    height: auto;

    &.cover {
      object-fit: cover;
    }

    &.contain {
      object-fit: contain;
    }

    &.fill {
      object-fit: fill;
    }

    &.none {
      object-fit: none;
    }

    &.scale-down {
      object-fit: scale-down;
    }
  }

  img {
    @include media-desktop {
      user-select: none;
      user-drag: none;
    }
  }
}

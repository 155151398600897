@import 'shared';

.root {
  @include page-spacing;
  @include motion-page-init;

  @include media-desktop {
    padding-bottom: px(109);
  }

  .content {
    @include mobile-grid;
    min-height: calc(100svh - $page-padding-mobile);
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'name name name name name name '
      'links links links links . . '
      'bio bio bio bio bio bio';
    row-gap: px(56);
    padding-bottom: px(48);

    // @NOTE: Override height hack
    /* stylelint-disable */
    @media (min-width: 435px) {
      height: unset;
    }
    /* stylelint-enable */

    @include media-tablet {
      @include tablet-grid;
      min-height: px(738);
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'name name name name name name name name '
        'links links links links . . . .'
        'bio bio bio bio bio bio bio bio';
      padding-bottom: px(37);
      row-gap: px(120);
    }

    @include media-desktop {
      @include desktop-grid;
      height: calc(100svh - $page-padding-desktop);
      grid-template-rows: auto 1fr auto;
      row-gap: 0;
      grid-template-areas:
        'name name name name name name name name name name . .'
        '. . . . . . . . . . . .'
        'links links links links . . bio bio bio bio bio bio';
    }

    .name {
      .titleHeaders {
        @include line-height-motion-fix;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% + $spacing);
      }
      grid-area: name;
      margin-top: px(-14);

      @include media-desktop-md {
        margin-top: 0;
      }

      .specialMargin {
        margin-bottom: px(5);

        @include media-tablet {
          margin-bottom: px(9);
        }
      }
    }

    .links {
      grid-area: links;
      columns: 2;

      @include media-tablet {
        margin-top: px(-44);
      }

      @include media-desktop {
        margin-top: 0;
      }

      .linkSection {
        @include columns-width(2, 2, 2);
        margin-bottom: px(26);

        @include media-tablet {
          margin-bottom: px(16);
        }

        .headerLabel {
          margin-bottom: px(4);

          @include media-desktop {
            margin-bottom: px(2);
          }
        }

        .ctaLink {
          margin-bottom: px(4);

          @include media-desktop {
            margin-bottom: px(2);
          }
        }
      }
    }

    .bio {
      grid-area: bio;

      @include media-tablet {
        margin-top: px(-24);
      }

      @include media-desktop {
        margin-top: auto;
      }

      .image {
        width: px(63);
        margin-bottom: px(24);

        @include media-tablet {
          @include columns-width(1, 1, 1);
          margin-bottom: px(32);
        }

        @include media-desktop {
          margin-bottom: px(35);
        }
      }

      .description {
        @include media-mobile {
          display: flex;
          flex-direction: column;
          gap: px(30);
        }

        @include media-tablet {
          column-gap: $spacing;
          columns: 2;
        }
      }
    }
  }

  .scrollContent {
    @include mobile-grid;
    scroll-padding-top: px(24);

    @include media-tablet {
      @include tablet-grid;
    }

    @include media-desktop {
      @include desktop-grid;
    }

    .position {
      grid-column: 1 / -1;

      @include media-tablet {
        grid-column: 5 / -1;
      }

      @include media-desktop {
        padding-top: px(30);
        grid-column: 7 / -1;
      }

      .paragraph {
        @include media-mobile {
          white-space: pre-line;
        }
      }
    }

    .bioSection {
      .label {
        margin-bottom: px(2);

        @include media-tablet {
          margin-bottom: px(2);
        }

        @include media-desktop {
          margin-bottom: 0;
        }
      }

      .miniSection {
        margin-bottom: $spacing;

        @include media-tablet {
          margin-bottom: px(24);
        }

        @include media-desktop {
          margin-bottom: px(18);
        }
      }

      &:last-child {
        p:last-child {
          @include media-desktop {
            max-width: 80%;
          }
        }
      }

      p {
        @include media-desktop {
          max-width: calc(50% - $spacing);
        }
      }

      &:not(:last-child) {
        margin-bottom: px(55);

        @include media-tablet {
          margin-bottom: px(64);
        }
      }
    }
  }

  .headerLabel {
    color: $grey;
  }
}

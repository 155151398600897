@use 'sass:map';

// ====================================================================
// Breakpoints
// ====================================================================
$breakpoint-mobile: 375;
$breakpoint-tablet: 768;
$breakpoint-desktop: 1280;
$breakpoint-desktop-md: 1440;
$breakpoint-desktop-lg: 1550;

// ====================================================================
// Design width
// ====================================================================
$design-width-mobile: 393;
$design-width-tablet: 834;
$design-width-desktop: 1440;
$design-lock: 1820;

// ====================================================================
// Base palette
// ====================================================================
$white: #fdfdfd;
$black: #000;
$grey: #909090;
$dark-grey: #101010;
$transparent: transparent;

// Specific backgrounds
$bg-vibia: #e8d2be;
$bg-pixel-arena: #121212;
$bg-sergi-comellas: #e5dfd9;
$bg-sonos-roam: #dbd7ad;
$bg-sonos-ace: #d4ba8d;
$bg-archive: #fdfdfd;

// ====================================================================
// Global variables used across the web app
// ====================================================================
$page-bg-color: $white;
$spacing: px(16);
$page-padding-mobile: px(67);
$page-padding-tablet: px(48);
$page-padding-desktop: px(20);

// Columns setup
$column-width-mobile: calc(((100vw - #{$spacing} * 2) / 6) - (#{$spacing} * 5 / 6));
$column-width-tablet: calc(((100vw - #{$spacing} * 2) / 8) - (#{$spacing} * 7 / 8));
$column-width-desktop: calc(((100vw - #{$spacing} * 2) / 12) - (#{$spacing} * 11 / 12));

// Eases
$ease-opacity: cubic-bezier(0.23, 1, 0.32, 1);
$ease-transform: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-scale: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-wipe: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-split-text: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-x-y-percent: cubic-bezier(0.4, 0, 0.1, 1);
$ease-slot-machine: cubic-bezier(0.65, 0, 0.2, 1);
$ease-image: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-transform-fast: cubic-bezier(0.19, 1, 0.22, 1);
$ease-jam3-loader: cubic-bezier(0.33, 0, 0, 1);

// Carousel spacing to matchi both /work and /gallery
$carousel-padding-hz-mobile: px(20);
$carousel-padding-hz-tablet: px(42);
$carousel-padding-hz-desktop: px(20);

// CSS Motion duration
$transform-duration: 0.667s;
$opacity-duration: 0.334s;

// Eases to test
$ease1: cubic-bezier(0.32, 0.83, 0.6, 1);
$ease2: cubic-bezier(0.32, 0.94, 0.6, 1);

// Eases to match css
$css-ease-out: cubic-bezier(0, 0, 0.58, 1);
$css-ease-in: cubic-bezier(0.42, 0, 1, 1);
$css-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
$css-ease-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$css-ease-linear: cubic-bezier(0, 0, 1, 1);

@import 'shared';

.root {
  display: block;

  &.h1 {
    @include typography-h1;
  }

  &.h2 {
    @include typography-h2;
  }

  &.h3 {
    @include typography-h3;
  }

  &.h4 {
    @include typography-h4;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.italic {
    font-style: italic;
  }
}

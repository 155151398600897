/* stylelint-disable custom-property-pattern */
@use 'sass:list';
@use 'sass:math';
@use 'sass:string';

@import 'vars';

/// Media queries
/// @example scss Input
///   element {
///     [...phone styles....]
///     @include media-tablet {
///        [...tablet styles...]
///     }
///     @include media-desktop {
///        [...desktop styles...]
///     }
///   }

@mixin media-below-mobile {
  @media (max-width: #{$breakpoint-mobile}px) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: #{$breakpoint-tablet - 1}px) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: #{$breakpoint-tablet}px) {
    @content;
  }
}

@mixin media-tablet-only {
  @media (min-width: #{$breakpoint-tablet}px) and (max-width: #{$breakpoint-desktop - 1}px) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: #{$breakpoint-desktop}px) {
    @content;
  }
}

@mixin media-desktop-md {
  @media (min-width: #{$breakpoint-desktop-md}px) {
    @content;
  }
}

@mixin media-desktop-lg {
  @media (min-width: #{$breakpoint-desktop-lg}px) {
    @content;
  }
}

/// Improve animation performance for containers
/// @example scss Input
///   .card {
///     @include gpu();
///   }
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

/// Set exactly the same width and height of a component, avoid repeat the same dimension
/// @parameter {Measure unit} $width - Specify width of the box
/// @parameter {Measure unit} $height [$width] - Specify height of the box, the default value is the width
/// @example scss Input
///   .card {
///     @include box(3.2rem);
///   }
///   .card-another {
///     @include box(3.2rem, 2rem);
///   }
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

/// Convert the pixels to rem for in a specific resolution, usually 1440px wide
/// @example scss Input
///   section {
///     height: px(200);
///   }
@function px($px) {
  @return $px * 0.1rem;
}

/// Set top and left to 0
/// @example scss Input
///   element {
///     @include position-0;
///   }
@mixin position-0 {
  top: 0;
  left: 0;
}

/// Set full size of box and position
/// @parameter {string} $pos - one of relative, absolute, fixed. relative by default
/// @example scss Input
///   element {
///     @include position-100(absolute);
///   }
@mixin position-100($pos: relative) {
  @include position-0;
  @include box(100%);
  position: $pos;
}

/// Set display flex and center children horizontally and vertically
/// @example scss Input
///   element {
///     @include flex-center;
///   }
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Set display flex and center children vertically
/// @example scss Input
///   element {
///     @include flex-center-vert;
///   }
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

/// Set display flex and center children horizontally
/// @example scss Input
///   element {
///     @include flex-center-horiz;
///   }
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/// Center its element (horizontally and vertically) by using transform
/// @example scss Input
///   element {
///     @include transform-center;
///   }
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/// Set vertical center of its element
/// @example scss Input
///   element {
///     @include transform-center-vert;
///   }
@mixin transform-center-vert {
  top: 50%;
  transform: translateY(-50%);
}

/// Set horizontal center of its element
/// @example scss Input
///   element {
///     @include transform-center-horiz;
///   }
@mixin transform-center-horiz {
  left: 50%;
  transform: translateX(-50%);
}

/// Set z-index value in order of $element variable
/// @example scss Input
///   element {
///     @include z-index(preloader);
///   }
@mixin z-index($id) {
  $elements: pages, main-nav, rotate;
  z-index: list.index($elements, $id);
}

/// Reset button's look
/// @example scss Input
///   button {
///     @include reset-button;
///   }
@mixin reset-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  color: currentcolor;
}

// Grid logic

@mixin desktop-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $spacing;
}

@mixin tablet-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: $spacing;
}

@mixin mobile-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: $spacing;
  row-gap: px(10);
}

@mixin page-spacing {
  overflow: hidden;
  min-height: 100svh;
  width: 100%;
  padding: $page-padding-mobile $spacing;

  @include media-tablet {
    padding: $page-padding-tablet $spacing;
  }

  @include media-desktop {
    padding: $page-padding-desktop $spacing;
  }
}

@mixin columns-width($mobile, $tablet, $desktop, $desktop-lg: false) {
  width: calc($column-width-mobile * $mobile + $spacing * (math.floor($mobile) - 1));

  @include media-tablet {
    width: calc($column-width-tablet * $tablet + $spacing * (math.floor($tablet) - 1));
  }

  @include media-desktop {
    width: calc($column-width-desktop * $desktop + $spacing * (math.floor($desktop) - 1));
  }

  @if $desktop-lg {
    @include media-desktop-lg {
      width: calc($column-width-desktop * $desktop-lg + $spacing * (math.floor($desktop-lg) - 1));
    }
  }
}

@function width-column-mobile($mobile) {
  @return calc($column-width-mobile * $mobile + $spacing * (math.floor($mobile) - 1));
}

@function width-column-tablet($tablet) {
  @return calc($column-width-tablet * $tablet + $spacing * (math.floor($tablet) - 1));
}

@function width-column-desktop($desktop) {
  @return calc($column-width-desktop * $desktop + $spacing * (math.floor($desktop) - 1));
}

@mixin list-styles() {
  .list {
    margin-top: 0;
  }

  ul {
    margin-top: 0.5em;

    > li {
      margin-left: 1em;
      position: relative;

      &::before {
        @include transform-center-vert;
        content: '';
        position: absolute;
        width: px(2);
        height: px(2);
        left: calc(-0.5em - #{px(2)});
        background-color: $white;
      }
    }
  }
}

@mixin breakout-wrapper {
  margin-left: -$spacing;
  margin-right: -$spacing;
}

@mixin line-height-motion-fix {
  line-height: 1.3 !important;
  margin: -0.15em 0 !important;
}

@mixin motion-page-init {
  opacity: 0;
}

@mixin autoalpha-0 {
  opacity: 0;
  visibility: hidden;
}

@mixin autoalpha-1 {
  opacity: 1;
  visibility: visible;
}

@mixin ui-transition($properties...) {
  $all-properties: '';

  @each $prop in $properties {
    $all-properties: $all-properties + $prop + ', ';
  }

  /* stylelint-disable-next-line plugin/no-low-performance-animation */
  transition-property: string.unquote($all-properties);
  transition-duration: 0.8s;
  transition-timing-function: $ease-transform;
}

@mixin projects-backgrounds {
  &.vibia {
    background-color: $bg-vibia;
  }

  &.pixel-arena {
    background-color: $bg-pixel-arena;
    color: $white;
  }

  &.sergi-comellas {
    background-color: $bg-sergi-comellas;
  }

  &.sonos-roam {
    background-color: $bg-sonos-roam;
  }

  &.sonos-ace {
    background-color: $bg-sonos-ace;
  }

  &.archive {
    background-color: $bg-archive;
  }
}

@mixin projects-colors {
  &.vibia {
    color: $black;
  }

  &.pixel-arena {
    color: $white;
  }

  &.sergi-comellas {
    color: $black;
  }

  &.sonos-roam {
    color: $black;
  }

  &.sonos-ace {
    color: $black;
  }

  &.archive {
    color: $black;
  }
}

@mixin rect($position: null, $top: null, $left: null, $width: null, $height: null) {
  @if $position {
    position: $position;
  }

  @if $top {
    top: $top;
  }

  @if $left {
    left: $left;
  }

  @include box($width, $height);
}

@import 'shared';

.root {
  --hover-line: 1px;
  --hover-offset: 0;

  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  cursor: pointer;

  .svg {
    @include box(px(16));
    margin-left: px(2);
  }

  &.withLineHover {
    &::after {
      content: '';
      position: absolute;
      bottom: var(--hover-offset);
      left: 0;
      width: 100%;
      height: var(--hover-line);
      background-color: currentcolor;
      transform: scaleX(0);
      transform-origin: 100% 50%;
      transition: transform 1s $ease-scale;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
      }
    }

    &.externalSvg {
      &::after {
        width: calc(100% - 1em);
      }
    }
  }

  &.withOpacityHover {
    transition: opacity 0.667s $ease-opacity;

    &:hover {
      opacity: 0.5;
    }
  }
}

@keyframes reveal-media {
  0% {
    transform: translateY(px(90));
    opacity: 0;
  }

  10% {
    transform: translateY(px(90));
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
